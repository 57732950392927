<template>
  <div>
    <!-- Header -->
    <b-row
      align-h="between"
      align-v="center"
    >
      <b-col
        class="mb-2 mb-md-0"
        cols="12"
      >
        <!-- Page title -->
        <h2 class="mb-0 mb-md-1 mb-lg-0">
          Data Kuantitatif-LAMDIK
        </h2>
      </b-col>
      <b-col cols="12">
        <b-row
          align-h="end"
          align-v="end"
          class="py-2"
        >
          <b-col
            class="mb-1 mb-md-0"
            md="4"
            lg="3"
          >
            <label for="ts">Tahun Semester :</label>
            <v-select
              id="ts"
              v-model="ts"
              :reduce="option => option.value"
              :options="tsOptions"
              class="bg-white w-100"
              @input="onTSChange"
            >
              <span slot="no-options">Operator belum ada yang mengisi profil.</span>
            </v-select>
          </b-col>
          <b-col
            md="4"
            lg="3"
            class="mb-1 mb-md-0"
          >
            <label for="ts">Program Studi :</label>
            <v-select
              id="department"
              v-model="department"
              :reduce="option => option.value"
              :options="departmentOptions"
              class="bg-white w-100"
              @input="onDepartmentChange"
            />
          </b-col>
          <b-col
            md="4"
            lg="6"
            class="text-md-right"
          >
            <b-button
              variant="warning"
              class="action-button"
              @click="exportAllData()"
            >
              <b-spinner
                v-if="spinnerDownload"
                small
                type="grow"
              />
              <feather-icon
                v-if="!spinnerDownload"
                icon="DatabaseIcon"
              />
              <span
                v-if="spinnerDownload"
                class="ml-1"
              >
                Unduh Semua. . .
              </span>
              <span
                v-else
                class="ml-1"
              >
                Unduh Semua
              </span>
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table -->
    <b-card body-class="p-0">
      <!-- Header -->
      <b-card-header>
        <b-col md="6">
          <h4 class="mb-1 mb-md-0">
            Daftar Tabel
          </h4>
        </b-col>
        <b-col md="6">
          <b-row>
            <b-col md="6">
              <v-select
                id="status"
                v-model="status"
                :options="statusesOptions"
                class="bg-white w-100"
                placeholder="Pilih status"
              />
            </b-col>
            <b-col md="6">
              <b-form-input
                v-model="searchKeyword"
                type="text"
                placeholder="Cari Tabel"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-card-header>

      <!-- Body -->
      <overlay-loading :loading="fetchLoading">
        <b-table
          class="m-0"
          responsive="sm"
          :items="reports"
          :fields="fields"
          show-empty
          hover
          caption-top
          primary-key="number"
        >
          <!-- No Data -->
          <template #empty>
            <p class="mb-0 py-2 text-center">
              <span class="d-block">Tidak ada data</span>
              <span v-show="!ts || (department && !department.length)">Silakan pilih <strong>Tahun Semester</strong> dan <strong>Program Studi</strong></span>
            </p>
          </template>

          <template #cell(action)="data">
            <div class="d-flex align-items-center">
              <b-button
                variant="primary"
                size="sm"
                class="action-button mr-1"
                :to="`/rev/lkps/laporan/${data.item.list_lkps_table.name_sheet}/${data.item.id}`"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
              <b-button
                v-if="data.item.link_file_excel"
                :pressed="true"
                variant="warning"
                class="action-button mr-1"
                :href="`${BACKEND_URL}/${data.item.link_file_excel}`"
                size="sm"
              >
                <feather-icon icon="DownloadIcon" />
              </b-button>
            </div>
          </template>
          <template #cell(status)="data">
            <b-badge :variant="statusVariantBadge(data.item.status || 'Tidak ada status')">
              <strong>{{ data.item.status ||'Tidak ada status' }}</strong>
            </b-badge>
          </template>
        </b-table>
      </overlay-loading>

      <!-- Footer -->
      <b-card-footer>
        <b-row
          align-h="between"
          align-v="center"
        >
          <b-col
            sm="2"
            class="mb-2 mb-sm-0"
          >
            <!-- Select amount of data per page -->
            <b-form-select
              v-model="pagination.selectedAmount"
              :options="pagination.amountOptions"
            />
          </b-col>
          <b-col
            col
            class="d-flex justify-content-center justify-content-sm-end"
          >
            <!-- Pagination -->
            <b-pagination
              :value="pagination.currentPage"
              :total-rows="pagination.totalItems"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
              :per-page="pagination.selectedAmount"
              @change="page => {
                $store.commit('app/UPDATE_REVIEWER_LKPS_PAGE', page)
                this.fetchReports('', page)
              }"
            />
          </b-col>
        </b-row>
      </b-card-footer>
    </b-card>

    <!-- Grade -->
    <Grade
      v-if="$can('reviewer', 'tingkat 2')"
      gradable="DK"
      :ts="ts"
      :department="department"
      :completed="completed"
    />
    <OnlyViewGrade
      v-else-if="$can('reviewer', 'tingkat 1') && reports.length && department.length && completed"
      gradable="DK"
      :ts="ts"
      :program-studi="department[0]"
      :short-name="department[1]"
    />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardFooter,
  BTable,
  BPagination,
  BFormSelect,
  BButton,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BForm,
  BDropdown,
  BDropdownHeader,
  BDropdownItem,
  BSpinner,
} from 'bootstrap-vue'
import VSelect from 'vue-select'

import { v4 as uuidv4 } from 'uuid'

import useJwt from '@/auth/jwt/useJwt'
import OverlayLoading from '@/views/components/OverlayLoading.vue'
import { getUserData } from '@/auth/utils'

import Grade from '../../components/reviewer/Grade.vue'
import OnlyViewGrade from '../../components/operator/Grade.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardFooter,
    BTable,
    BPagination,
    BFormSelect,
    BButton,
    BFormInput,
    BFormGroup,
    OverlayLoading,
    VSelect,
    BFormTextarea,
    BForm,
    BDropdown,
    BDropdownHeader,
    BDropdownItem,
    BSpinner,
    Grade,
    OnlyViewGrade,
  },
  data() {
    return {
      // Table fields, data, pagination
      completed: false,
      spinnerDownload: false,
      fields: [
        { key: 'number', label: '#' },
        { key: 'number_and_title', label: 'Nomor dan Judul Tabel' },
        { key: 'status', label: 'Status', thStyle: { width: '300px' } },
        { key: 'action', label: 'Aksi' },
      ],
      reports: [],
      pagination: {
        amountOptions: [5, 10, 25, 50, 100],
        selectedAmount: 10,
        totalItems: 0,
        currentPage: 1,
      },
      searchKeyword: '',
      timeoutDebounce: null,
      status: '',
      statusesOptions: [
        'Selesai',
        'Perlu Diperbaiki',
        'Dapat Ditinjau',
        'Dapat Ditinjau Ulang',
        'Proses Pengisian',
        'Tidak ada status',
      ],

      // Tahun Semester & Department
      ts: '',
      department: [],
      tsOptions: [],
      departmentOptions: [],

      // loading
      fetchLoading: false,
      BACKEND_URL: process.env.VUE_APP_BACKEND_URL,
    }
  },
  computed: {
    statusVariantBadge() { return any => this.$variantBadge[any] },
  },
  watch: {
    // 'pagination.currentPage': function (page) {
    //   this.$store.commit('app/UPDATE_REVIEWER_LKPS_PAGE', page)
    //   this.fetchReports()
    // },
    'pagination.selectedAmount': function () {
      this.fetchReports()
    },
    searchKeyword(keyword) {
      if (keyword.length <= 0 || keyword.length >= 3) {
        if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
        this.timeoutDebounce = setTimeout(() => this.fetchReports(keyword), 1000)
      }
    },
    status() {
      this.fetchReports()
    },
  },
  mounted() {
    const { ts, department } = this.$store.state.app.reviewer
    this.ts = ts
    this.department = department

    this.fetchTSOptions()
    this.fetchDeparmentOptions()

    this.$nextTick(() => {
      const lastSelectedPage = this.$store.state.app.reviewerLkpsPage
      if (this.pagination.currentPage == lastSelectedPage) {
        this.fetchReports()
      } else {
        this.fetchReports('', lastSelectedPage)
      }
    })
  },
  methods: {
    fetchReports(keyword = '', page = this.pagination.currentPage) {
      if (this.ts && this.department && this.department.length) {
        this.fetchLoading = true

        const [program_studi, short_name] = this.department

        useJwt.http.get('lkps_table', {
          params: {
            page: page - 1,
            size: this.pagination.selectedAmount,
            sort: 'ASC',
            ts: this.ts,
            program_studi,
            short_name,
            number_and_title: keyword,
            status: this.status,
          },
        })
          .then(response => {
            let number = ((page * this.pagination.selectedAmount) - this.pagination.selectedAmount) + 1
            this.reports = response.data.lkps_tables.map(list => {
              list.number_and_title = list.list_lkps_table.number_and_title
              list.uuid = uuidv4()
              list.action = ''
              list.number = number++
              return list
            })

            this.pagination.totalItems = response.data.total_items
            this.completed = response.data.completed
          })
          .catch(error => this.$alert({ title: error.message, variant: 'danger', icon: 'XIcon' }))
          .finally(() => {
            this.fetchLoading = false
            this.$nextTick(() => this.pagination.currentPage = page)
          })
      }
    },
    onTSChange(value) {
      this.fetchReports()
      this.$store.commit('app/UPDATE_REVIEWER_INFO', { ...this.$store.state.app.reviewer, ts: value })
    },
    onDepartmentChange(value) {
      this.fetchReports()
      this.$store.commit('app/UPDATE_REVIEWER_INFO', { ...this.$store.state.app.reviewer, department: value })
    },
    fetchTSOptions() {
      useJwt.http.get('ts_options')
        .then(response => {
          this.tsOptions = response.data.ts_options.map(ts => ({ label: ts.replace('/', ' / '), value: ts }))
        })
    },
    fetchDeparmentOptions() {
      useJwt.http.get('department', {
        params: {
          size: 555,
        },
      })
        .then(response => {
          this.departmentOptions = response.data.departments.map(department => ({
            label: `${department.program_studi} (${department.lkps_program.short_name})`,
            value: [department.program_studi, department.lkps_program.short_name],
          }))
        })
    },
    // testing
    exportAllData() {
      this.spinnerDownload = true
      const [program_studi, short_name] = this.department
      useJwt.http.get('lkps/export/excel', {
        params: {
          ts: this.ts,
          program_studi,
          short_name,
        },
      })
        .then(response => {
          this.$alert({ title: response.data.message, variant: 'success', icon: 'CheckIcon' })
          window.open(`${this.BACKEND_URL}/${response.data.link_file_excel}`)
        })
        .catch(() => this.$alert({ title: 'Telah terjadi kesalahan.', variant: 'danger', icon: 'XIcon' }))
        .finally(() => this.spinnerDownload = false)
    },
  },
}
</script>
