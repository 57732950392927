<template>
  <!-- Grade -->
  <b-card v-show="$can('reviewer', 'tingkat 2') && ts && department && completed">
    <b-card-header header-class="px-0 pt-0">
      <h4 class="mb-0">
        Penilaian
      </h4>
    </b-card-header>

    <b-row>
      <b-col
        md="3"
        lg="2"
      >
        <b-form-group>
          <label for="nilai">Nilai akreditasi</label>
          <b-form-input
            id="nilai"
            v-model="nilai"
            type="number"
            placeholder="1 - 400"
          />
        </b-form-group>
        <template v-if="nilai">
          <p v-if="!(nilai > 0 && nilai <= 400)" class="invalid-feedback d-block">Masukan nilai antara 1 - 400</p>
          <p class="font-weight-bold" v-else>{{ text }}</p>
        </template>
      </b-col>
      <b-col
        md="9"
        lg="10"
      >
        <label>Dokumen pendukung</label>
        <b-form-group>
          <v-dropzone
            id="files"
            ref="files"
            :options="{
              url: BACKEND_URL,
              maxFilesize: 5,
              autoProcessQueue: false,
              acceptedFiles,
              previewTemplate: dropzoneTemplate(),
            }"
            duplicate-check
            use-custom-slot
            add-remove-links
            @vdropzone-file-added="addFile"
            @vdropzone-removed-file="removeFile"
          >
            <h5 class="mb-0">
              Masukkan berkas yang diperlukan (opsional)
            </h5>
          </v-dropzone>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-button
          variant="primary"
          :disabled="gradeLoading || !(nilai > 0 && nilai <= 400)"
          @click="gradeReportPerTS"
        >
          {{ gradeLoading ? 'Menyimpan...' : 'Simpan' }}
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue'

import useJwt from '@/auth/jwt/useJwt'
import moment from 'moment'

import VDropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    BCard,
    BCardHeader,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BButton,
    VDropzone,
  },
  props: {
    gradable: [String, Number],
    ts: String,
    department: {
      type: Array,
      required: false,
      default: () => ['', '']
    },
    completed: Boolean,
    userId: {
      type: [String, Number],
      required: false,
      default: null
    },
  },
  data() {
    return {
      nilai: '',
      text: '',
      files: [],
      gradeLoading: false,
      BACKEND_URL: process.env.VUE_APP_BACKEND_URL,
    }
  },
  watch: {
    nilai(value) {
      if (value >= 361 && value <= 400) {
        this.text = 'Unggul'
      } else if (value >= 301 && value <= 360) {
        this.text = 'Sangat baik'
      } else if (value >= 251 && value <= 300) {
        this.text = 'Baik'
      } else {
        this.text = 'Tidak memenuhi syarat akreditasi'
      }
    },
    department() {
      this.fetchGrade()
    }
  },
  computed: {
    hasReviewed() {
      if (this.dataMaster) {
        return this.comments.some(comment => comment.comment != 'Semua sudah benar')
      }

      return false
    },
    acceptedFiles() {
      return this.gradable == 'DK' ? 'application/vnd.ms-excel,excel,spreadsheetml,.xlsx' : 'application/pdf'
    }
  },
  created() {
    this.fetchGrade()
  },
  methods: {
    moment,
    fetchGrade() {
      this.fetchGradeLoading = true
      useJwt.http.get(`grade`, {
        params: {
          type: this.gradable,
          ts: this.ts,
          program_studi: this.department[0],
          short_name: this.department[1]
        }
      })
        .then(response => {
          if (response.data.grade) {
            this.nilai = response.data.grade.grade
  
            this.$refs.files.removeAllFiles(true)
            this.files = response.data.grade.grade_files.map(file => {
              const mockFile = {
                id: file.id,
                name: file.original_name,
                unique_name: file.unique_name,
                size: 12345,
                type: file.type,
                upload: {
                  uuid: uuidv4(),
                },
              }
              this.$refs.files.manuallyAddFile(mockFile, `${this.BACKEND_URL}/${file.unique_name}`)
  
              return mockFile
            })
          }
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.fetchGradeLoading = false
          this.$emit('on-fetch', this.hasReviewed)
        })
    },
    dropzoneTemplate() {
      return `
        <div class="dz-preview dz-file-preview relative">
          <div class="dz-image">
            <img data-dz-thumbnail>
          </div>
          <div class="dz-details">
            <div class="dz-filename">
              <h5 class="text-white" data-dz-name></h5>
              <div class="dz-size mt-1" data-dz-size></div>
            </div>
          </div>
          <div class="dz-error-message">
            <span data-dz-errormessage></span>
          </div>
          <div class="dz-success-mark"></div>
          <a class="dz-remove absolute mr-1" style="left: 0; right: 0;" href="javascript:undefined;" data-dz-remove>Hapus</a>
        </div>
      `
    },
    addFile(file) {
      this.files.push(file)
    },
    removeFile(removedFile) {
      this.files = this.files.filter(file => file.upload.uuid != removedFile.upload.uuid)
    },
    gradeReportPerTS() {
      const formData = new FormData()
      formData.append('nilai', this.nilai)

      const files = []
      this.files.forEach(file => {
        if (file instanceof File) {
          formData.append('files', file)
        } else {
          files.push(file)
        }
      })

      formData.append('files', JSON.stringify(files))

      this.gradeLoading = true

      useJwt.http.put(
        'grade',
        formData,
        {
          params: {
            type: this.gradable,
            ts: this.ts,
            program_studi: this.department[0],
            short_name: this.department[1],
            user_id: this.userId
          },
        },
      )
        .then(response => this.$alert({ title: response.data.message, variant: 'success', icon: 'CheckIcon' }))
        .finally(() => this.gradeLoading = false)
    },
  }
}
</script>

<style>
  .dz-preview {
    z-index: 10 !important;
    max-height: 150px !important;
  }

  .vue-dropzone>.dz-preview .dz-details {
    background-color: #009B4C !important;
  }

  .dz-image {
    max-height: 150px !important;
  }
</style>